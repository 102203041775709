import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
  api: 'http://localhost:3001/',
  apimKey: '5f5a3d56d8d846eb9e52281f23edf1a7',
  appInsights: {
    instrumentationKey: '29337826-d4e9-4b28-9f09-9dd75fe9c921',
  },
  authApiUrl: 'https://scecp-auth.dnv.com/Auth',
  baseUrl: 'https://portal.test.dnv.com',
  certificateGraphqlHost:
    'https://sce-certservice-test.azurewebsites.net/graphql',
  contactGraphqlHost: 'https://testapi.dnv.com/ba-scecp-contactservice-test',
  contractsGraphqlHost:
    'https://sce-contractsservice-dev.azurewebsites.net/graphql',
  documentsApi: 'https://testapi.dnv.com/basceps-dev/v1/api/Documents',
  findingGraphqlHost: 'https://testapi.dnv.com/ba-scecp-findingservice-test',
  invoicesGraphqlHost:
    'https://sce-invoiceservice-test.azurewebsites.net/graphql',
  auditGraphqlHost: 'https://testapi.dnv.com/ba-scecp-auditservice-test',
  newCertificateGraphqlHost:
    'https://testapi.dnv.com/ba-scecp-certificateservice-test',
  production: true,
  scheduleGraphqlHost:
    'https://sce-scheduleservice-test.azurewebsites.net/graphql',

  newScheduleGraphqlHost:
    'https://testapi.dnv.com/ba-scecp-scheduleservice-test',
  veracityUrl: 'https://id.veracity.com/',
};
